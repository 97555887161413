.stock-transactions-container {
    width: 90%;
    margin: 20px auto;
    text-align: center;
}

h1 {
    margin-bottom: 15px;
    font-size: 24px;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.styled-table th, .styled-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.styled-table th {
    background-color: #004085;
    color: #fff;
}

.styled-table td {
    background-color: #f9f9f9;
}
