.stock-availability-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .filters-container input,
  .filters-container select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .filter-button {
    background-color: green;
    color: white;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .filter-button:hover {
    background-color: darkgreen;
  }
  
.filters {
    display: flex;
    width: 300px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.filters select,
.filters input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    width: 1000px;
    border-radius: 5px;
}

.stock-table {
    width: 90%;
    border-collapse: collapse;
}

.stock-table th, .stock-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.stock-table th {
    background-color: rgb(6, 58, 146);
    color: white;
}

.stock-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.stock-table tr:hover {
    background-color: #f1f1f1;
}

