.journal-entries-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure full viewport height */
    box-sizing: border-box;
}
.search-container {
    margin-bottom: 15px;
    
    text-align: right;
}
.date-filter-container {
    display: flex;
    align-items: end;
    gap: 5px;  /* Reduced gap for closer spacing */
    justify-content: right;
    justify-items: right;
    margin-bottom: 15px;
    text-align: right;
    margin-top: 15px;
    width: 50%;
    
}

.date-filter-container label {
    font-weight: bold;
    margin-right: 2px; /* Reduce spacing between label and input */
}

.date-filter-container input {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 130px; /* Reduced width */
}

.date-filter-container button {
    background-color: green;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px; /* Ensures the button is not too far */
}

.date-filter-container button:hover {
    background-color: darkgreen;
}

.search-bar-wrapper {
    position: relative;
    display: inline-block;
    width: 300px;
}

.search-container {
    margin-bottom: 15px;
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
    width: 100%;
}

.search-container {
    margin: 0 auto; /* Center horizontally */
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center the entire container */
    width: 100%;
    max-width: 500px;
}


.search-icon {
    font-size: 25px;
    margin-right: 10px; /* Space between icon and search bar */
    color: #666;
}

.search-bar {
    flex: 1; /* Allow the search bar to take up remaining space */
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}



h1 {
    text-align: center;
    margin-bottom: 20px;
}

.entry-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.entry-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

label {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    text-align: right;
}

label input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.entry-row label {
    flex-basis: 150px;
}

.entry-row input {
    flex: 2;
}

.add-entry-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: rgb(6, 58, 146);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-entry-btn:hover {
    background-color: rgb(6, 58, 146);
}

.table-container {
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100vh - 150px); /* Dynamically adjust height based on viewport */
}

.entries-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.entries-table th, .entries-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.entries-table th {
    background-color: rgb(6, 58, 146);
    color: white;
    font-weight: bold;
}

.entries-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.entries-table tr:hover {
    background-color: #f1f1f1;
}

@media (max-width: 768px) {
    .entry-row {
        flex-direction: column;
        align-items: flex-start;
    }
    
    label {
        text-align: left;
    }
}
