/* Align radio buttons horizontally and center them properly */
.radio-group {
    display: flex;
    justify-content: center; /* Center the radio buttons */
    align-items: center; /* Align radio buttons and text vertically */
    gap: 10px; /* Reduce the gap between radio buttons */
    margin-bottom: 20px;
}

.radio-group label {
    display: flex;
    align-items: center; /* Align the radio button and text */
    gap: 5px; /* Reduce space between the button and text */
    font-weight: bold;
    color: rgb(6, 58, 146);
}


.service-transaction-container {
    max-height: 80vh; /* Adjust the height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; /* Prevents horizontal scrolling */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

html, body {
    height: 100%; /* Ensure the body takes up full height */
    margin: 0;
    padding: 0;
    overflow: hidden; /* Prevent the whole page from scrolling */
}

.main-container {
    height: 100vh; /* Full viewport height */
    overflow-y: auto; /* Adds scrollbar to the main layout */
}



/* Form content layout */
.form-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    gap: 20px;
}

/* Form group styling */
.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Align labels and inputs horizontally */
.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: rgb(6, 58, 146);
}

.form-group input,
.form-group select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Make inputs consistent */
}

/* Buttons container */
.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

