/* Ensure the body and html take full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Set the background color of the body */
body {
  background-color: black; /* Black background color */
  overflow: hidden; /* Prevent scrollbars if not needed */
}

/* Container to hold the video background and content */
.front-page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Background video */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
  filter: blur(4px); /* Add blur effect */
  background-color: black;
}

/* Overlay to darken the background video for better text visibility */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Header containing the Admin Login button */
.header {
  position: fixed; /* Position the header fixed at the bottom */
  bottom: 20px; /* Align it to the bottom */
  width: 100%;
  display: flex; /* Use flex to center the button */
  justify-content: center; /* Center horizontally */
  /* padding: 0 20px; */
}

/* Admin Login button styling */
.admin-login {
  background-color: rgb(6 58 146);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-login:hover {
  background-color: #0056b3;
}

/* Content area for the main text */
.content {
  text-align: center;
  color: white;
}

/* Responsive design */

/* Tablet and iPad landscape size */
@media (max-width: 1024px) {
  .front-page-container {
    height: 75vh; /* Reduce height for tablet/iPad landscape */
  }

  .background-video {
    height: 75vh; /* Adjust video height */
  }
}

/* Mobile devices and iPad portrait size */
@media (max-width: 768px) {
  .front-page-container {
    height: 60vh; /* Reduce height further for mobile/iPad portrait */
  }

  .background-video {
    height: 60vh; /* Adjust video height */
  }

  .content h1 {
    font-size: 2rem;
  }

  .admin-login {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .front-page-container {
    height: 50vh; /* Further reduce height for small devices */
  }

  .background-video {
    height: 50vh; /* Adjust video height */
  }
}
