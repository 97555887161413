.service-transaction-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.stock-toggle {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center the entire toggle */
    gap: 10px; /* Reduce space between radio buttons */
    margin-bottom: 10px;
}

.stock-toggle label {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    gap: 5px; /* Reduce space between radio input and label text */
}

.stock-toggle input[type="radio"] {
    margin: 0;
    transform: scale(1.1); /* Slightly increase size for better UI */
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group select,
.form-group input,
.form-group textarea {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 95%;
}

textarea {
    resize: none;
    height: 60px;
}

.radio-group {
    display: flex;
    gap: 20px;
}

.radio-group label {
    font-weight: normal;
}

.button-container {
    text-align: center;
    margin-top: 20px;
}

.add-stock-btn {
    background-color: rgb(6, 58, 146);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

.add-stock-btn:hover {
    background-color: darkblue;
}

/* Responsive Design */
@media (max-width: 768px) {
    .form-grid {
        grid-template-columns: 1fr;
    }
}
