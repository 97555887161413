/* Enable full-page scrolling */
html, body {
    height: 100vh;
    width: 100vw;
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; /* Hides horizontal scrollbar if not needed */
    margin: 0;
    padding: 0;
}

/* Ensure main content fills available space */
.main-content {
    margin-left: 0; /* Adjust for full-width usage */
    padding: 20px;
    width: 100%; /* Full-width for the content */
    box-sizing: border-box;
    min-height: 100vh; /* Ensure content spans full viewport */
    overflow-y: auto; /* Enables scrolling inside the main content */
}

/* Ensuring the table scrolls horizontally when needed */
.table-container {
    width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling */
    max-height: 80vh; /* Prevents the table from overflowing too much */
}

/* Styling the scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey background */
}

::-webkit-scrollbar-thumb {
    background: #888; /* Darker grey for scrollbar handle */
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Even darker on hover */
}

  
  .table {
    width: 100%; /* Ensure the table spans the full width of its container */
    border-collapse: collapse;
  }
.daily-sales-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
}

.filter-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.filter-container label {
    font-size: 1rem;
    color: #333;
}

.filter-container input {
    padding: 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.daily-sales-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.daily-sales-table th, .daily-sales-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-size: 0.9rem;
}

.daily-sales-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.daily-sales-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.daily-sales-table tr:hover {
    background-color: #ddd;
}

.summary-card {
    background: #ffffff; /* White Background */
    color: #333; /* Dark Gray Text */
    padding: 12px;
    border-radius: 6px;
    width: 35%; /* Reduced width for compact size */
    margin: 15px auto;
    font-size: 13px; /* Smaller font */
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08); /* Softer shadow */
    border: 1px solid #ddd; /* Light border */
}

.summary-content {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.summary-row {
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 500;
    border-bottom: 1px solid #e0e0e0; /* Light separator */
}

.summary-title {
    font-size: 14px; /* Smaller title */
    font-weight: bold;
    color: #1e1e2f; /* Dark Blue */
    padding-bottom: 6px;
    border-bottom: 2px solid #2a72c3; /* Blue line under title */
}

.summary-highlight {
    background: #eaf3ff; /* Light Blue Background */
    color: #1e1e2f; /* Dark Blue Text */
    padding: 4px;
    border-radius: 3px;
}

.total {
    font-weight: bold;
    background: rgba(240, 240, 240, 0.5);
    padding: 6px;
    border-radius: 3px;
    text-align: center;
}

.cash-received {
    background: #eaf3ff; /* Light Blue */
    color: #1e1e2f; /* Dark Blue */
    font-weight: bold;
    padding: 6px;
    border-radius: 3px;
    text-align: center;
}
