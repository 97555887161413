/* General Styles */
body {
    background-color: white;
    font-family: Arial, sans-serif;
}

.expenses-container {
    max-width: 900px; /* Limit width for better readability */
    margin: 20px auto; /* Center the container */
    padding: 20px;
    background-color: white;
    color: rgb(6, 58, 146);
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
    display: grid;
    gap: 20px;
}

/* Grid layout for the form */
.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    gap: 20px; /* Spacing between items */
}

/* Form group styling */
.form-group {
    display: flex;
    flex-direction: column; /* Labels and inputs stacked */
    gap: 5px; /* Spacing between label and input */
}

.form-group label {
    font-size: 14px;
    font-weight: bold;
    color: rgb(6, 58, 146);
    text-align: left; /* Align labels to the left */
}

input[type="text"],
input[type="date"],
select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box; /* Include padding in width calculation */
    width: 100%;
}

/* Input Focus Effect */
input[type="text"]:focus,
input[type="date"]:focus,
select:focus {
    border-color: rgb(6, 58, 146);
    outline: none;
}

/* Button container */
.button-container {
    text-align: center;
    margin-top: 20px;
}

.add-expense-btn {
    background-color: rgb(6, 58, 146);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-expense-btn:hover {
    background-color: rgb(28, 56, 104);
}

/* Responsive Design */

/* Tablet view */
@media (max-width: 768px) {
    .form-grid {
        grid-template-columns: 1fr; /* Switch to single-column layout */
    }

    .add-expense-btn {
        padding: 8px 16px;
        font-size: 14px;
    }
}

/* Mobile view */
@media (max-width: 480px) {
    .expenses-container {
        width: 95%;
        padding: 15px;
    }

    .form-group label {
        font-size: 12px;
    }

    input[type="text"],
    input[type="date"],
    select {
        padding: 8px;
        font-size: 12px;
    }

    .add-expense-btn {
        font-size: 12px;
        padding: 10px 15px;
    }
}
