.payments-container {
  width: 80%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.radio-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.radio-label {
  margin-right: 20px;
  font-weight: bold;
  cursor: pointer;
}

.radio-label input {
  margin-right: 8px;
}

.selected {
  color: #007bff;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
}

select,
input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.balance-box {
  background: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 15px 0;
  text-align: center;
  border-radius: 4px;
}

.payment-form {
  margin-top: 15px;
}


.submit-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
}

.submit-button:hover {
  background: #0056b3;
}

.message {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
  color: green;
}

/* Align fields in a single row */
.form-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Style inline form groups */
.form-group-inline {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
}

/* Make payment amount take the full width */
.form-group-inline.full-width {
  width: 30%;
  text-align: center;
}

/* Center button */
.form-row.centered {
  justify-content: center;
}
