.stock-management-container {
    padding: 20px;
  }
  
  .stock-toggle {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input, select, textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .add-stock-btn {
    background-color: #0052cc;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-stock-btn:hover {
    background-color: #003d99;
  }
  