.ledger-selector {
    display: flex;
    align-items: center;
    gap: 15px; /* Adjust space between elements */
    justify-content: center; /* Center all elements */
    margin-bottom: 20px;
  }
  .highlighted-row {
    background-color: #b4d8f8 !important; /* Ensure highlight overrides any row color */
  }
  
  .dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px; /* Space between label and dropdown */
  }
  
  #entityTypeSelect,
  #entitySelect {
    padding: 8px;
    font-size: 16px;
    width: 200px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: darkgreen;
  }
  .entries-table-container {
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    border: 1px solid #ddd; /* Adds a border to the container */
    margin-top: 20px; /* Adds some space above the table */
    border-radius: 4px; /* Rounded corners for the container */
}

.entries-table {
    width: 100%; /* Make the table take full width of the container */
    border-collapse: collapse; /* Remove spacing between table cells */
}

.entries-table th, .entries-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd; /* Adds borders to cells */
}

.entries-table th {
  background-color: #003366; /* Dark Blue Background */
  color: white; /* White Text */
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

  