.invoices-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.form-group-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

#company {
  flex: 1;
  max-width: 300px;
  margin-right: 10px;
}

.show-services-button {
  height: 40px;
  margin-left: 10px;
}

.services-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.entries-table-container
{
  
  margin-bottom: 20px;
}

.services-table th, .services-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.services-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.button-group {
  display: flex;
  justify-content: center; /* Centers the buttons */
  gap: 10px; /* Adds space between buttons */
  margin-bottom: 20px; /* Adds some space below the group */
  flex-wrap: wrap; /* Ensures buttons wrap to the next line if the screen width is small */
}

.generate-pdf-button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #0056b3; /* Button background color */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-pdf-button:hover {
  background-color: #003f7d; /* Darker background on hover */
}

