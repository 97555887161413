.chart-of-accounts-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.account-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.account-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

label {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    text-align: right; /* Align the label text to the right */
}

label input, label select {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.account-row label {
    flex-basis: 150px; /* Set a fixed width for the label */
}

.account-row input, .account-row select {
    flex: 2;
}

.add-account-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: rgb(6, 58, 146);;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-account-btn:hover {
    background-color: rgb(6, 58, 146);;
}

.table-container {
    margin-top: 30px;
    max-height: 300px;
    overflow-y: auto; /* Makes the table scrollable */
}

.accounts-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.accounts-table th, .accounts-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.accounts-table th {
    background-color: rgb(6, 58, 146);
    font-weight: bold;
}

.accounts-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.accounts-table tr:hover {
    background-color: #f1f1f1;
}

@media (max-width: 768px) {
    .account-row {
        flex-direction: column;
        align-items: flex-start; /* Align inputs and labels on small screens */
    }
    
    label {
        text-align: left; /* Align label text to left on small screens for better UX */
    }
}
