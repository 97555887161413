/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.channel-list-container {
  display: flex;
}

/* Sidebar */
.sidebar {
  background-color: #1e3872;
  color: white;
  width: 250px;
  height: 100vh;
  position: fixed;
  left: -250px;
  transition: left 0.3s ease;
  overflow-y: auto;
  padding: 20px;
}

.sidebar.open {
  left: 0;
}

/* Hamburger Icon */
.hamburger-icon {
  position: fixed;
  top: 20px;
  left: 10px; /* Align with the sidebar */
  z-index: 1000;
  cursor: pointer;
  color: #1e3872;
}

.hamburger-image {
  width: 30px; /* Adjust the size */
  height: 30px;
  cursor: pointer;
}

.channel-list__header {
  text-align: center;
  margin-bottom: 20px;
}

.channel-list__header__text {
  font-size: 1.2rem;
  font-weight: bold;
}

.channel-list__buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.channel-list__button {
  background-color: #1e3872;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.channel-list__button:hover,
.channel-list__button.active {
  background-color: #14284a;
}

/* Main Content */
.main-content {
  margin-left: 0;
  padding: 20px;
  transition: margin-left 0.3s ease;
  width: 100%;
}

.main-content.shrink {
  margin-left: 250px;
}
