.reports-container {
    width: 90%;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    text-align: center;
    margin-bottom: 10px; /* 🔹 Reduce space below title */
}

.table-container {
    width: 100%;
    max-width: 900px;
    overflow-x: auto; /* ✅ Ensures tables remain scrollable */
    margin-bottom: 5px; /* 🔹 Reduce gap between tables */
    padding-bottom: 0px; /* ✅ Remove extra padding */
}

h2 {
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 5px; /* 🔹 Reduce gap between headers and tables */
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 0px !important; /* 🔹 Remove extra space below table */
}

.styled-table th, .styled-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.styled-table th {
    background-color: #004085;
    color: #fff;
}

.balance-amount {
    text-align: right;
    font-weight: bold;
}

.total-row {
    background-color: #f8f9fa;
    font-weight: bold;
}

.total-amount {
    text-align: right;
    color: #d9534f;
}

/* ✅ Remove excess gap between the two tables */
.table-container:last-child {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
