/* TrialBalance.css */
.trial-balance {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  h1{
    text-align: center;
}
  .trial-balance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .trial-balance-table th,
  .trial-balance-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  
  .note {
    margin-top: 10px;
    font-style: italic;
  }
  