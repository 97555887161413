.stock-sales-container {
    padding: 20px;
  }
  
  .filters-container {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .filters-container input {
    padding: 8px;
    border: 1px solid #ccc;
  }
  
  .stock-sales-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .stock-sales-table th, .stock-sales-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .stock-sales-table th {
    background-color: #0a347d;
    color: white;
  }
  
  .stock-sales-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  