html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; /* Prevent default scrollbars on the body */
}

.dashboard-container {
    padding: 20px;
  }
  
  .filter-container {
    margin-bottom: 15px;
  }
  
  .chart-container {
    width: 100%;
    height: 400px; /* Fixed height */
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  

h1 {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
}

.overall-summary,
.daily-summary {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-item {
    flex: 1 1 calc(20% - 10px);
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-item h3 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 5px;
}

.summary-item p {
    font-size: 1rem;
    color: #555;
}

.charts-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}



.chart-label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px; /* Space between label and chart */
    text-align: center;
}



.filter-container label {
    font-size: 1rem;
    margin-right: 10px;
}

.filter-container select {
    font-size: 1rem;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

.line-chart {
    width: 100%;
    height: 400px; /* Ensure sufficient height */
    margin-bottom: 20px;
}
